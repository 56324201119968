import React from "react"
import styled from "styled-components"

import Layout from "./../layouts/Layout/Layout"
import HeroSubpage from "../sections/HeroSubpage/HeroSubpage"

import Background from "../assets/images/hero_news.jpg"
import { graphql } from "gatsby"
import ArticleAbstract from "../components/ArticleAbstract/ArticleAbstract"
import { LayoutWrapper } from "../components/LayoutWrapper/LayoutWrapper"

const StyledArticlesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -80px;
  margin-top: 90px;
  @media screen and (max-width: 1000px) {
    margin-bottom: -30px;
    margin-top: 60px;
  }
  @media (max-width: 767px) {
    margin-top: 40px;
  }
`

const StyledListItem = styled.div`
  width: calc(100% / 3);
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 80px;
  @media screen and (max-width: 1000px) {
    width: 50%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 30px;
  }
`

const Articles = ({ data }) => {
  const articles = data.allDatoCmsArticle.edges

  return (
    <Layout>
      <HeroSubpage title="Aktualności" backgroundImage={Background} />
      <LayoutWrapper>
        <StyledArticlesList>
          {articles.map(({ node }) => {
            console.log(node.slug)
            return (
              <StyledListItem>
                <ArticleAbstract
                  imageUrl={node.image.url}
                  title={node.title}
                  excerpt={node.abstract}
                  url={node.slug}
                />
              </StyledListItem>
            )
          })}
        </StyledArticlesList>
      </LayoutWrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    allDatoCmsArticle(sort: {fields: meta___createdAt, order: DESC}) {
      edges {
        node {
          slug
          title
          content
          abstract
          image {
            alt
            url
          }
        }
      }
    }
  }
`

export default Articles
