import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { fonts } from "../../utils/fonts"

const StyledImageWrapper = styled.figure`
  padding-bottom: 58%;
  overflow: hidden;
  position: relative;
`

const StyledImage = styled.img`
  object-fit: cover;
  position: absolute;
  height: 100%;
  width: 100%;
`

const StyledTitle = styled.h3`
  ${fonts.headingExtraSmall};
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
`

const StyledExcerpt = styled.p`
  margin-top: 24px;
`

const StyledLink = styled.a`
  margin-top: 50px;
  font-size: 23px;
  line-height: 33px;
  text-decoration: underline;
  display: inline-block;
  font-weight: 700;
  @media screen and (max-width: 767px) {
  font-size: 20px;
    margin-top: 20px;
  }
`

const ArticleAbstract = ({ imageUrl, title, excerpt, url }) => {
  return (
    <article>
      <StyledImageWrapper>
        <StyledImage src={imageUrl} alt="" />
      </StyledImageWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledExcerpt>{excerpt}</StyledExcerpt>
      <StyledLink href={url}>Czytaj więcej</StyledLink>
    </article>
  )
}

ArticleAbstract.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
}

export default ArticleAbstract
