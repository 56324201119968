import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rgba } from "polished"
import { fonts } from "../../utils/fonts"
import { color } from "../../utils/colors"
import { LayoutWrapper } from "../../components/LayoutWrapper/LayoutWrapper"

const StyledWrapper = styled.section`
  height: 480px;
  background-color: ${color.navyBlue};
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1100px) {
    height: 400px;
  }
`

const StyledLayoutWrapper = styled(LayoutWrapper)`
  padding-top: 80px;
  position: relative;
`

const StyledBackgroundLayout = styled(LayoutWrapper)`
  width: 1370px;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  @media screen and (max-width: 1370px) {
  width: calc(100vw - 40px)
  }
`

const StyledTitle = styled.h2`
  ${fonts.headingLarge};
  color: ${color.white};
`

const StyledImageWrapper = styled.figure`
  position: absolute;
  left: 33.33%;
  right: calc((100vw - 1370px) / -2);
  height: 100%;
  &::before {
    content: "";
    background-color: ${rgba(color.navyBlue, 0.5)};
    mix-blend-mode: multiply;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  @media screen and (max-width: 1370px) {
    right: -20px;

  }
`

const StyledImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`

const HeroSubpage = ({ title, backgroundImage }) => (
  <StyledWrapper>
    <StyledBackgroundLayout>
      <StyledImageWrapper>
        <StyledImage src={backgroundImage} alt="" />
      </StyledImageWrapper>
    </StyledBackgroundLayout>
    <StyledLayoutWrapper>
      <StyledTitle>{title}</StyledTitle>
    </StyledLayoutWrapper>
  </StyledWrapper>
)

HeroSubpage.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
}

export default HeroSubpage
